import React, {useRef} from 'react';
import generatePDF from "react-to-pdf";

const Document = ({children, className}) => {
    const targetRef = useRef();

    return (
        <div className={`relative ${className}`}>
            <button className='fixed border border-water text-water hover:border-water-light hover:text-water-light rounded-md text-sm p-1 top-24 right-20' onClick={() => generatePDF(targetRef, {filename: 'page.pdf'})}>Download PDF</button>
            <div ref={targetRef} className='flex flex-col'>
               {children}
            </div>
        </div>
    );
};

export default Document;
