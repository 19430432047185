import React, {useEffect, useState} from 'react';
import gdiLogo from '../../../static/images/template/pdf/grand-digital/GRAND_DIGITAL_Bildmarke_RGB.png'
import pdfPerformance from '../../../static/images/template/pdf/overview/pdf-performance.svg'
import pdfBackup from '../../../static/images/template/pdf/overview/pdf-backup.svg'
import pdfHealth from '../../../static/images/template/pdf/overview/pdf-health.svg'
import pdfGeneral from '../../../static/images/template/pdf/overview/pdf-general.svg'
import pdfBackupActive from '../../../static/images/template/pdf/overview/pdf-backup-active.svg'
import pdfBackupDisabled from '../../../static/images/template/pdf/overview/pdf-backup-disabled.svg'
import pdfTheme from '../../../static/images/template/pdf/overview/pdf_theme.svg'
import pdfBackupFrequency from '../../../static/images/template/pdf/overview/pdf-backup-frequency.svg'
import pdfUptime from '../../../static/images/template/pdf/overview/pdf-uptime.svg'
import pdfTotalUpdates from '../../../static/images/template/pdf/overview/pdf-total-updates.svg'
import pdfTotalVulnerabilities from '../../../static/images/template/pdf/overview/pdf-total-vulnerability.svg'
import {useParams} from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {API_SITE_BACKUPS, API_SITE_BACKUPS_SCHEDULE_INFO, API_SITE_PLUGINS, API_SITE_STATISTIC_DESKTOP, API_SITE_STATISTIC_MOBILE, API_SITES, API_SITES_SSL_INFO} from "../../../api/endpoints";
import {toast} from "react-toastify";
import Document from "../../../static/images/template/partials/Document";
import Page from "../../../static/images/template/partials/Page";
import PluginTablePage from "../../../static/images/template/partials/PluginTablePage";
import {MdCloudUpload, MdOnlinePrediction, MdOutlineCloudDone, MdOutlineUpdate} from "react-icons/md";
import { LuDatabaseBackup } from "react-icons/lu";
import {IoTimerOutline, IoWarningOutline} from "react-icons/io5";
import { BsDatabaseCheck } from "react-icons/bs";
import {TbDatabaseExport} from "react-icons/tb";
import php from "../../../static/images/site/php.svg";
import {FaWordpress} from "react-icons/fa6";
import {FaExpeditedssl} from "react-icons/fa";
import {HiPaintBrush} from "react-icons/hi2";
import {HiOutlineStatusOnline} from "react-icons/hi";

const GrandDigitalPdfTemplate = ({ data }) => {
    const params = useParams()
    const axios = useAxiosPrivate()
    const [sitePlugins, setSitePlugins] = useState([])
    const [backups, setBackups] = useState([])
    const [backupSchedule, setBackupSchedule] = useState({});
    const [site, setSite] = useState({});
    const [statisticsDesktop, setStatisticsDesktop] = useState({});
    const [statisticsMobile, setStatisticsMobile] = useState({});
    const [sslInfo, setSSLInfo] = useState({});

    // Get Site Plugins
    useEffect(() => {
        const fetchSitePlugins = async () => {
            try{
                const response = await axios(API_SITE_PLUGINS(params?.orgId, params?.siteId))
                setSitePlugins(response?.data)
            }catch(err){
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data?.detail)
                }else {
                    toast.error("Fetch site plugins failed")
                }
            }
        }
        fetchSitePlugins()
    }, [])

    // Get Site Backup and Backup Schedule
    useEffect(() => {
        const fetchBackupsScheduleInfo = async (backup_id) => {
            try{
                const response = await axios.get(API_SITE_BACKUPS_SCHEDULE_INFO(params?.orgId, params?.siteId, backup_id))
                setBackupSchedule(response?.data)
            }catch(err){
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data?.detail)
                }else {
                    toast.error("Fetch backups schedule info failed")
                }
            }
        }
        const fetchBackupData = async () => {
            try{
                const response = await axios.get(API_SITE_BACKUPS(params?.orgId, params?.siteId))
                setBackups(response?.data)
                const backupId = response?.data[0]?.id
                if (backupId){
                    fetchBackupsScheduleInfo(backupId)
                }
            }catch(err){
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data?.detail)
                }else {
                    toast.error("Fetch backups failed")
                }
            }
        }
        fetchBackupData()
    }, [])

    // Get Site Data
    useEffect(() => {
        const fetchSiteData = async () => {
            try{
                const response = await axios(API_SITES(params?.orgId, params?.siteId))
                setSite(response?.data)
            }catch(err){
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data?.detail)
                }else {
                    toast.error("Fetch site failed")
                }
            }
        }
        fetchSiteData()
    },[])

    // Get Desktop Performance
    useEffect(() => {
        const fetchDesktopStatistic = () => {
            axios
                .get(API_SITE_STATISTIC_DESKTOP(params?.orgId, params?.siteId))
                .then((response) => {
                    setStatisticsDesktop(response?.data)
                })
                .catch((err) => {
                    const error_data = err?.response?.data?.errors?.[0] || null
                    if (error_data) {
                        toast.error(error_data.detail)
                    }else {
                        toast.error(`Fetching desktop statistics failed`)
                    }
                })
        }
        fetchDesktopStatistic()
    }, [])

    // Get Mobile Performance
    useEffect(() => {
        const fetchMobileStatistic = () => {
            axios
                .get(API_SITE_STATISTIC_MOBILE(params?.orgId, params?.siteId))
                .then((response) => {
                    setStatisticsMobile(response?.data)
                })
                .catch((err) => {
                    const error_data = err?.response?.data?.errors?.[0] || null
                    if (error_data) {
                        toast.error(error_data.detail)
                    }else {
                        toast.error(`Fetching mobile statistics failed`)
                    }
                })
        }
        fetchMobileStatistic()
    }, []);

    // Get SSL Info
    useEffect(() => {
        const fetchSiteSSlInfo = async () => {
            try{
                const response = await axios(API_SITES_SSL_INFO(params?.orgId, params?.siteId))
                setSSLInfo(response?.data)
            }catch(err){
                if (err?.response?.status === 404) {
                    setSSLInfo({
                        issuer: "No SSL",
                        valid_from: "No SSL",
                        valid_to: "No SSL",
                    })
                    return
                }
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data?.detail)
                }else {
                    toast.error("Fetch site ssl certification failed")
                }
            }
        }
        fetchSiteSSlInfo()
    }, [])

    // Utils
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const generalOverview = () => {
        const overview = {
            plugins: {
                availableUpdates: false,
                critical: 0,
                high: 0,
                medium: 0,
                low: 0,
            },
            wordpress: {
                availableUpdates: false
            },
            php: {
                availableUpdates: false
            },
            theme: {
                availableUpdates: false
            }
        }
    }

    return (
        <div className='flex justify-center'>

            <Document className='bg-white'>
                <Page className="p-2">
                    <div className='flex flex-col justify-between h-full p-2 border rounded-sm'>
                        <div className='w-full flex justify-start items-start'><img className='h-20' src={gdiLogo} alt="logo"/></div>
                        <div className='flex flex-col justify-center items-center'>
                            <div className='text-guinea uppercase font-oswald text-7xl'>Maintenance Report</div>
                            <div className='text-guinea text-6xl mt-8'>{site?.name}</div>
                            <div className='text-ultimate text-2xl mt-8'>{formatDate(new Date())}</div>
                        </div>
                        <div className='text-ultimate font-istok text-lg w-full text-end mb-2'>Report generated by <span className='text-guinea'>GRAND DIGITAL</span></div>
                    </div>
                </Page>

                <Page className='p-2'>
                    <div className='border rounded-sm h-full p-2'>
                        <div className='flex items-center gap-x-4'>
                            <div><img src={pdfGeneral} alt="general" className='w-10 h-10'/></div>
                            <div className='text-guinea text-2xl font-oswald mb-4'>General Overview</div>
                        </div>

                        <div className='border-b-2 border-b-ultimate mt-3'></div>

                        <div className='mt-12 text-ultimate-dark'>
                            <div className='flex justify-around'>
                                <div className='flex flex-col justify-center items-center w-fit gap-y-2'>
                                    <div><img src={php} alt="php" className='w-20 h-20'/></div>
                                    <div className='text-center'>{site?.php_version ?? 'N/A'}</div>
                                </div>

                                <div className='flex flex-col justify-center items-center w-fit gap-y-2'>
                                    <div><FaWordpress className='w-20 h-20 text-guinea'/></div>
                                    <div className='text-center'>{site?.wordpress_version ?? 'N/A'}</div>
                                </div>

                                <div className='flex flex-col justify-center items-center w-fit gap-y-2'>
                                    <div><FaExpeditedssl className='w-20 h-20 text-guinea'/></div>
                                    <div className='text-center'>{sslInfo?.issuer ?? 'N/A'}</div>
                                </div>

                                <div className='flex flex-col justify-center items-center w-fit gap-y-2'>
                                    <div><MdOnlinePrediction className='w-20 h-20 text-guinea'/></div>
                                    <div className='text-center'>{site?.is_connected ? 'Online' : 'Disconnected'}</div>
                                </div>
                            </div>
                        </div>

                        <div className='mt-12 flex text-ultimate-dark justify-between gap-x-4'>
                            <div className='flex border w-full rounded-md p-4 gap-x-4 relative overflow-hidden'>
                                <div className='h-fit'><img src={pdfTheme} alt="theme" className='w-16 h-16'/></div>
                                <div className='flex flex-col'><span className='text-xl text-guinea'>Website active theme</span> <span>{site?.active_theme ?? 'N/A'}</span></div>
                                <HiPaintBrush className='absolute w-28 h-28 right-0 text-page-bg -top-2'/>
                            </div>
                            <div className='flex border w-full rounded-md p-4 gap-x-4 relative overflow-hidden'>
                                <div className='h-fit'><img src={pdfBackupFrequency} alt="backup-frequency" className='w-16 h-16'/></div>
                                <div className='flex flex-col'><span className='text-xl text-guinea'>Auto backup</span> <span>{backupSchedule?.status ? 'Active' : 'Disabled'}</span></div>
                                <MdCloudUpload className='absolute w-28 h-28 right-0 text-page-bg -top-2'/>
                            </div>
                        </div>

                        <div className='mt-12 flex text-ultimate-dark justify-between gap-x-4 '>
                            <div className='flex border w-full rounded-md p-4 gap-x-4 relative overflow-hidden'>
                                <div className='h-fit'><img src={pdfTotalUpdates} alt="theme" className='w-12 h-12'/></div>
                                <div className='flex flex-col z-30'><span className='text-md text-guinea'>Available updates</span> <span>set</span></div>
                                <MdOutlineUpdate className='absolute w-28 h-28 right-0 text-page-bg -top-2'/>
                            </div>
                            <div className='flex border w-full rounded-md p-4 gap-x-4 relative overflow-hidden'>
                                <div className='h-fit'><img src={pdfUptime} alt="backup-frequency" className='w-12 h-12'/></div>
                                <div className='flex flex-col z-30'><span className='text-md text-guinea'>Uptime</span> <span>N/A</span></div>
                                <HiOutlineStatusOnline className='absolute w-28 h-28 right-0 text-page-bg -top-2'/>
                            </div>
                            <div className='flex border w-full rounded-md p-4 gap-x-4 relative overflow-hidden'>
                                <div className='h-fit'><img src={pdfTotalVulnerabilities} alt="backup-frequency" className='w-12 h-12'/></div>
                                <div className='flex flex-col z-30'><span className='text-md text-guinea'>Vulnerabilities</span> <span>set</span></div>
                                <IoWarningOutline className='absolute w-28 h-28 right-0 text-page-bg -top-2'/>
                            </div>
                        </div>
                    </div>
                </Page>

                <Page className='p-2'>
                    <div className='border rounded-sm h-full p-2'>
                        <div className='flex items-center gap-x-4'>
                            <div><img src={pdfHealth} alt="general" className='w-10 h-10'/></div>
                            <div className='text-guinea text-2xl font-oswald mb-4'>Site Health</div>
                        </div>
                        <div className='border-b-2 border-b-ultimate mt-3'></div>
                    </div>
                </Page>

                <Page className='p-2'>
                    <div className='border rounded-sm h-full p-2'>
                        <div className='flex items-center gap-x-4'>
                            <div><img src={pdfBackup} alt="general" className='w-10 h-10'/></div>
                            <div className='text-guinea text-2xl font-oswald mb-4'>Site Backups</div>
                        </div>
                        <div className='border-b-2 border-b-ultimate mt-3'></div>

                        <div className='mt-8'>
                            {backups && backups.length > 0 && backupSchedule?.status ? (
                                 <div className='flex p-2 items-center'>
                                    <img src={pdfBackupActive} alt="backup-active" className='w-20 h-20'/>
                                    <div className='text-ultimate-dark ml-4'>Backup is currently active. Your data is being securely saved to prevent any potential loss. You can rest assured that your important information is protected and up to date.</div>
                                 </div>
                            ): (
                                <div className='flex p-2 items-center'>
                                    <img src={pdfBackupDisabled} alt="backup-active" className='w-20 h-20'/>
                                    <div className='text-ultimate-dark ml-4'>Backup is currently disabled. Your data is not being saved at this time, and any changes made may be at risk of loss. Please enable the backup feature to ensure your data is protected.</div>
                                </div>
                            )}
                        </div>

                        <div className='text-guinea text-xl mt-8'>Backup Summary</div>
                        <div className='flex flex-col gap-y-4 mt-8'>
                            <div className='flex justify-between gap-x-4'>
                                <div className='flex w-full p-2 items-center text-guinea rounded-md border shadow-md'>
                                    <div className='h-fit'><MdOutlineCloudDone className='w-10 h-10'/></div>
                                    <div className='flex flex-col ml-2'><span>{backupSchedule?.execution_count ?? 'N/A'}</span><span className='text-ultimate-dark'>Success backups</span></div>
                                </div>
                                <div className='flex w-full p-2 items-center text-guinea rounded-md border shadow-md'>
                                    <div className='h-fit'><LuDatabaseBackup className='w-10 h-10'/></div>
                                    <div className='flex flex-col ml-2'><span>Monthly</span><span className='text-ultimate-dark'>Backup frequency</span></div>
                                </div>
                                <div className='flex w-full p-2 items-center text-guinea rounded-md border shadow-md'>
                                    <div className='h-fit'><IoTimerOutline  className='w-10 h-10'/></div>
                                    {
                                        backupSchedule && backupSchedule?.status ? (
                                            <div className='flex flex-col ml-2'><span>Active</span><span className='text-ultimate-dark'>Scheduled backup</span></div>
                                        ): (
                                            <div className='flex flex-col ml-2'><span>Disabled</span><span className='text-ultimate-dark'>Scheduled backup</span></div>
                                        )}
                                </div>
                            </div>
                        </div>

                    <div className='flex w-full justify-between gap-x-4 mt-8'>
                            <div className='flex border w-full rounded-md px-2 py-4 shadow-md'>
                                <div className='h-fit'><BsDatabaseCheck className='text-guinea w-16 h-16'/></div>
                                <div className='flex flex-col ml-4'><span className='text-guinea text-lg'>Last executed backup</span> <span className='text-ultimate-dark'>{backupSchedule?.last_execution_time ?? 'N/A'}</span></div>
                            </div>
                            <div className='flex border w-full rounded-md px-2 py-4 shadow-md'>
                                <div className='h-fit'><TbDatabaseExport className='text-guinea w-16 h-16'/></div>
                                <div className='flex flex-col ml-4'><span className='text-guinea text-lg'>Next scheduled backup</span> <span className='text-ultimate-dark'>{backupSchedule?.next_run_time ?? 'N/A'}</span></div>
                            </div>
                        </div>

                    </div>
                </Page>

                <Page className='p-2'>
                    <div className='border rounded-sm h-full p-2'>
                    <div className='flex items-center gap-x-4 '>
                            <div><img src={pdfPerformance} alt="performance" className='w-10 h-10'/></div>
                            <div className='text-guinea text-2xl font-oswald mb-4'>Site Performance</div>
                        </div>
                        <div className='border-b-2 border-b-ultimate mt-2'></div>
                    </div>
                </Page>

                <PluginTablePage plugins={sitePlugins}/>

            </Document>
        </div>
    );
};

export default GrandDigitalPdfTemplate;
