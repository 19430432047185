import React from "react";

const WpPlugin = (props) => {
    const {
        name,
        currentVersion,
        lastVersion,
        cvssScore,
        severity,
        active} = props

    const getRowColor = () => {
        if (severity === 'Critical' || severity === 'High') {
            return "text-spot"
        }
        if (!active) {
            return "text-gray-600"
        }
        return "text-ultimate"
    }

    return (
        <>
            <tr className=''>
                <td className={`text-[12px] border-b border-b-gray-200 font-istok  py-2 max-w-[30ch] truncate ${getRowColor()}`}>{name}</td>
                <td className={`text-[12px] border-b border-b-gray-200 font-oswald py-2  ${getRowColor()}`}>{currentVersion}</td>
                <td className={`text-[12px] border-b border-b-gray-200 font-oswald py-2  ${getRowColor()}`}>{lastVersion}</td>
                <td className={`text-[12px] border-b border-b-gray-200 font-oswald py-2  ${getRowColor()}`}>{cvssScore}</td>
                <td className={`text-[12px] border-b border-b-gray-200 font-istok  py-2  ${getRowColor()}`}>{severity}</td>
            </tr>
        </>
    );
};

export default WpPlugin;