import {useContext, useEffect, useState} from "react";
import useAuthService from "../hooks/useAuthService";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "./AppContext";
import logout from "../static/images/navbar/logout.svg";
import {toast} from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {API_USER_PROFILES_SELF} from "../api/endpoints";
import organizationLogo from "../static/images/organization/organization.svg"

const NavBar = ({pageName}) => {
    const authService = useAuthService()
    const navigate = useNavigate()
    const { navbarData, userData, setUserData } = useContext(AppContext);
    const axios = useAxiosPrivate()
    const params = useParams()

    const [orgName, setOrgName] = useState(null);
    const [orgImage, setOrgImage] = useState(null);

    const fetchUserData = async () => {
        try {
            const response = await axios(API_USER_PROFILES_SELF)
            setUserData({
                id: response?.data?.id,
                email: response?.data?.email,
                firstName: response?.data?.first_name,
                lastName: response?.data?.last_name,
                organizations: response?.data?.organizations
            })
            if (params?.orgId) {
                const current_organization = response?.data?.organizations.filter(org => org.organization.id === parseInt(params?.orgId))[0];
                setOrgName(current_organization.organization.name)
                setOrgImage(current_organization.organization.image)
            }
        }catch (error) {
            toast("Get user info failed", {type: 'error'})
            console.log(error)
        }
    }

    useEffect(() => {
        fetchUserData()
    }, []);

    const logoutHandler = () => {
        authService.logout()
        setUserData(null)
        navigate('/login')
    }

    return (
        <div className="bg-eclipse h-nav fixed flex justify-between w-screen top-0 items-center px-6 z-10">
            <div className='flex items-center'>
                <Link to={params?.orgId ? `/organization/${params.orgId}`: '/organizations'} className='h-8 flex items-center -ml-2'>
                    <img src={orgImage !== null ? `${orgImage}` : `${organizationLogo}`} alt="logo" className='transition duration-700 hover:scale-[103%] w-9 h-9'/>
                    <div className='ml-4 uppercase text-guinea font-oswald'>{orgName}</div>
                </Link>

                <div className='ml-10 font-oswald text-guinea font-light'>{navbarData && <span>{navbarData.name}</span>}</div>
            </div>

            <div className='flex items-center'>
                <div className='mr-20 font-oswald font-light text-guinea'>{userData && <div>{userData.firstName} {userData.lastName}</div>}</div>
                <div onClick={logoutHandler} className='flex flex-col justify-center items-center cursor-pointer hover:text-guinea transition duration-200'>
                    <img src={logout} alt="logout" className='w-6 h-6'/>
                    <span className='text-nav-size text-guinea hover:text-guinea-light'>Logout</span>
                </div>
            </div>
        </div>
    );
};

export default NavBar;