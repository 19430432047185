import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
// import Unauthorized from "./components/Unauthorized";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Sites from "./pages/Sites";
import Tools from "./pages/Tools";
import AppContainer from "./components/AppContainer";
import {AppProvider} from "./components/AppContext";
import Site from "./pages/Site";
import SiteReports from "./pages/SiteReports";
import NotFound from "./pages/NotFound";
import Organizations from "./pages/Organizations";
import OrganizationRegister from "./pages/OrganizationRegister";
import Organization from "./pages/Organization";
import SiteBackups from "./pages/SiteBackups";
import SitePlugins from "./pages/SitePlugins";
import Members from "./pages/Members";
import Settings from "./pages/Settings";
import ResetPassword from "./pages/ResetPassword";
import Home from "./pages/Home";
import ConfirmRegistration from "./pages/ConfirmRegistration";
import OrganizationRegisterConfirmation from "./pages/OrganizationRegisterConfirmation";
import ConfirmInvitation from "./pages/ConfirmInvitation";
import SitesVulnerable from "./pages/SitesVulnerable";
import UploadPluginPage from "./pages/UploadPluginPage";
import RepositoryPlugins from "./pages/RepositoryPlugins";
import RepositoryVendors from "./pages/RepositoryVendors";
import AuroraPdfTemplate from "./components/templates/pdf/AuroraPdfTemplate";
import GrandDigitalPdfTemplate from "./components/templates/pdf/GrandDigitalPdfTemplate";

const App = () => {
    return (
        <AppProvider>
            <BrowserRouter>
                <Routes>
                    <Route exact path='/' element={<Home/>}/>
                    <Route exact path='/login' element={<Login/>}/>
                    <Route exact path='/confirm-registration' element={<ConfirmRegistration/>}/>
                    <Route exact path='/organization-register' element={<OrganizationRegister/>}/>
                    <Route exact path='/organization-register-confirmation' element={<OrganizationRegisterConfirmation/>}/>
                    <Route exact path='/registration' element={<Registration/>}/>
                    <Route exact path='/reset-password' element={<ResetPassword/>}/>
                    <Route exact path='/reset-password' element={<ResetPassword/>}/>
                    <Route exact path='/confirm-invitation' element={<ConfirmInvitation/>}/>
                    {/*<Route exact path='/unauthorized' element={<Unauthorized/>}/>*/}
                    <Route exact path='/organizations' element={<PrivateRoute redirectTo='/login'><Organizations/></PrivateRoute>}/>

                    <Route path='/organization/:orgId' element={<PrivateRoute redirectTo='/login'><AppContainer/></PrivateRoute>}>
                        <Route index element={<PrivateRoute redirectTo='/login'><Organization/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/dashboard' element={<PrivateRoute redirectTo='/login'><Dashboard/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/members' element={<PrivateRoute redirectTo='/login'><Members/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/settings' element={<PrivateRoute redirectTo='/login'><Settings/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/sites' element={<PrivateRoute redirectTo='/login'><Sites/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/sites-vulnerable' element={<PrivateRoute redirectTo='/login'><SitesVulnerable/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/tools' element={<PrivateRoute redirectTo='/login'><Tools/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/site/:siteId' element={<PrivateRoute redirectTo='/login'><Site/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/site/:siteId/reports' element={<PrivateRoute redirectTo='/login'><SiteReports/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/site/:siteId/backups' element={<PrivateRoute redirectTo='/login'><SiteBackups/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/site/:siteId/plugins' element={<PrivateRoute redirectTo='/login'><SitePlugins/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/repository/upload-file' element={<PrivateRoute redirectTo='/login'><UploadPluginPage/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/repository/plugins' element={<PrivateRoute redirectTo='/login'><RepositoryPlugins/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/repository/vendors' element={<PrivateRoute redirectTo='/login'><RepositoryVendors/></PrivateRoute>}/>
                        <Route exact path='/organization/:orgId/site/:siteId/site-report' element={<PrivateRoute redirectTo='/login'><GrandDigitalPdfTemplate/></PrivateRoute>}/>
                    </Route>
                    <Route exact path='*' element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        </AppProvider>
    );
};

export default App;
