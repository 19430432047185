import React, {useEffect, useState} from 'react';
import email from '../static/images/sites-vulnerability/vul-email.svg'
import report from '../static/images/sites-vulnerability/vul-report.svg'
import cardPlaceholder from "../static/images/sites/site-card-placeholder.png";
import {useNavigate, useParams} from "react-router-dom";

const SiteVulnerableCard = ({data}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [site, setSite] = useState(data);
    const [vulnerabilities, setVulnerabilities] = useState([]);

    useEffect(() => {
        const plugins = data?.plugins.filter(plugin =>
            plugin.vulnerabilities.some(vulnerability =>
              vulnerability.severity === 'Critical' || vulnerability.severity === 'High'
            )
        );

        const vulnerabilityList = []

        plugins.forEach((plugin) => {
            const siteVulnerabilityData = {
                pluginName: plugin.name,
                pluginSlug: plugin.slug,
                pluginId: plugin.id,
                pluginVulnerability: plugin.vulnerabilities
                    .filter(vul => vul.severity === 'Critical' || vul.severity === 'High')
                    .sort((a, b) => {
                      if (a.severity === 'Critical' && b.severity !== 'Critical') return -1;
                      if (a.severity !== 'Critical' && b.severity === 'Critical') return 1;
                      return 0;
                    })[0]
            }
            vulnerabilityList.push(siteVulnerabilityData)
        })

        setVulnerabilities(vulnerabilityList)
    }, [data])

    const redirectToReportHandler = () => {
        navigate(`/organization/${params?.orgId}/site/${data?.id}/site-report`)
    }

    return (
        <div className={`flex border ${site?.is_connected ? 'border-gray-600' : 'border-spot'} rounded-md p-4 gap-4`}>
            <div className='flex flex-col gap-y-4 min-w-[200px] max-w-[200px]'>
                <div className={`text-center ${site?.is_connected ? 'text-guinea' : 'text-spot'}`}>{site?.name}</div>
                <a href={site?.url} target='_blank' data-tip={site?.url} className='tooltip tooltip-bottom '><img src={site?.image ?? cardPlaceholder} alt="site_image" className='transition duration-200 hover:scale-105 rounded-md'/></a>
                <div className='flex gap-x-2'>
                    <img className='w-10 h-10 cursor-pointer transition duration-200 hover:scale-105' src={email} alt="vul-email"/>
                    <img onClick={redirectToReportHandler} className='w-10 h-10 cursor-pointer transition duration-200 hover:scale-105' src={report} alt="vul-email"/>
                </div>
            </div>

            <div className="text-sm space-y-4 w-full">
                {vulnerabilities &&
                    vulnerabilities.map((vulnerability, index) => (
                        <div key={vulnerability?.pluginId} className="border border-gray-700 rounded-lg shadow-lg p-6 bg-gradient-to-r from-eclipse to-guinea-dark text-ultimate w-full hover:border-guinea">
                            <div className="flex gap-x-4 items-center mb-3">
                                <span className="font-bold min-w-28 text-guinea">Plugin Name</span>
                                <span className="text-ultimate">{vulnerability?.pluginName}</span>
                            </div>
                            <div className="flex gap-x-4 items-center mb-3">
                                <span className="font-bold min-w-28 text-guinea">Plugin Slug</span>
                                <span className="text-ultimate">{vulnerability?.pluginSlug}</span>
                            </div>
                            <div className="flex gap-x-4 items-center mb-3">
                                <span className="font-bold min-w-28 text-guinea">Title</span>
                                <span className="text-ultimate">{vulnerability?.pluginVulnerability?.description}</span>
                            </div>
                            <div className="flex gap-x-4 items-center mb-3">
                                <span className="font-bold min-w-28 text-guinea">Description</span>
                                <span className="text-ultimate">{vulnerability?.pluginVulnerability?.description_long}</span>
                            </div>
                            <div className="flex gap-x-4 items-center mb-3">
                                <span className="font-bold min-w-28 text-guinea">Severity</span>
                                <span className="text-spot-light">{vulnerability?.pluginVulnerability?.severity}</span>
                            </div>
                            <div className="flex gap-x-4 items-center mb-3">
                                <span className="font-bold min-w-28 text-guinea">Patched</span>
                                <span className="text-ultimate">{vulnerability?.pluginVulnerability?.patched}</span>
                            </div>
                            <div className="flex gap-x-4 items-center mb-3">
                                <span className="font-bold min-w-28 text-guinea">Patched Version</span>
                                <span className="text-ultimate">{vulnerability?.pluginVulnerability?.patched_version}</span>
                            </div>
                            <div className="flex gap-x-4 items-center mb-3">
                                <span className="font-bold min-w-28 text-guinea">Remediation</span>
                                <span className="text-ultimate">{vulnerability?.pluginVulnerability?.remediation}</span>
                            </div>
                            <div className="flex gap-x-4 items-center">
                                <span className="font-bold min-w-28 text-guinea">Reference</span>
                                <a href={vulnerability?.pluginVulnerability?.reference} target='_blank' className="text-water hover:text-water-light transition duration-300">{vulnerability?.pluginVulnerability?.reference ? 'More Info': 'Not Available'}</a>
                            </div>
                        </div>
                    ))}
            </div>


        </div>
    );
};

export default SiteVulnerableCard;