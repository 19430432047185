import React, {useEffect, useState} from 'react';
import CanvasJSReact from "@canvasjs/react-charts";
import pdfPlugin from '../../../images/template/pdf/overview/pdf-plugin.svg'
import Page from "./Page";
import PluginTable from "./PluginTable";

const PluginTablePage = (props) => {
    const {plugins} = props;
    const [sitePlugins, setSitePlugins] = useState([])
    const [remainingPlugins, setRemainingPlugins] = useState([])
    const pluginNumberSplitter = 18
    const pluginsPerPage = 24
    const [pluginsData, setPluginsData] = useState({})

    const splitPluginsByCategory = (pluginList) => {
        const category = {
            "critical": 0,
            "high": 0,
            "medium": 0,
            "low": 0,
            "hasUpdate": 0,
            "disabled": 0,
            "total": pluginList.length,
        }

        pluginList.forEach(plugin => {
            if (!plugin.active) {
                category.disabled++;
            }

            if (plugin.available_version !== "") {
                category.hasUpdate++;
            }
            const severity = plugin?.vulnerabilities ? plugin?.vulnerabilities.sort((a, b) => b.cvss_score - a.cvss_score)[0]?.severity : " "

            if (severity === "Critical") {
                category.critical++;
            }
            if (severity === "High") {
                category.high++;
            }
            if (severity === "Medium") {
                category.medium++;
            }
            if (severity === "Low") {
                category.low++;
            }
        })

        setPluginsData(category)
    }

    useEffect(()=>{
        if (plugins.length > pluginNumberSplitter){
            setSitePlugins(plugins.slice(0, pluginNumberSplitter));

            const remaining = plugins.slice(pluginNumberSplitter);
            const chunks = [];

            for (let i = 0; i < remaining.length; i += pluginsPerPage) {
                chunks.push(remaining.slice(i, i + pluginsPerPage));
            }

            setRemainingPlugins(chunks);
        }
        else {
            setSitePlugins(plugins)
        }

        splitPluginsByCategory(plugins)
    }, [plugins])

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const element = document.querySelector('.canvasjs-chart-credit');
                if (element) {
                    element.remove();
            }
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
        return () => observer.disconnect();
    }, []);

    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;
    CanvasJS.addColorSet('plugins', [
        "#e65757",
        "#ed7a7a",
        "#a69065",
        "#4d7063",
        "#70ae9a",
        "#82918c",
    ])

    const optionsPlugin = {
        animationEnabled: true,
        exportEnabled: false,
        theme: "light2",
        axisY:{
            // labelFontColor: "transparent",
            tickColor: "transparent",
            gridColor: "transparent",
        },
            title:{
            text: "Wordpress Installed Plugins",
            fontFamily: "Oswald, sans-serif",
            fontColor: "#8d7b54"
        },
        backgroundColor: "transparent",
        colorSet: 'plugins',
        data: [{
            type: "column",
            indexLabel: "{label}: {y}",
            indexLabelFontColor: "#8d7b54",
            // startAngle: -90,
            dataPoints: [
                { y: pluginsData?.critical, label: "Critical" },
                { y: pluginsData?.high, label: "High" },
                { y: pluginsData?.medium, label: "Medium" },
                { y: pluginsData?.low, label: "Low" },
                { y: pluginsData?.hasUpdate, label: "Has update" },
                { y: pluginsData?.disabled, label: "Disabled" },
            ],

        }]
    }


    return (
        <div>
            <Page className='p-2'>
                <div className='p-2 border rounded-sm'>
                    <div className='flex items-center gap-x-4'>
                        <div><img src={pdfPlugin} alt="general" className='w-10 h-10'/></div>
                        <div className='text-guinea text-2xl font-oswald mb-4'>Installed Plugins Overview</div>
                    </div>
                    <div className='border-b-2 border-b-ultimate mt-3'></div>

                    <div className='border rounded-sm mt-4'>
                        <CanvasJSChart options={optionsPlugin} containerProps={{width: '100%', height: '200px'}}/>
                    </div>

                    {sitePlugins && sitePlugins.length > 0 &&
                        <div className='border rounded-sm mt-4'>
                            <PluginTable plugins={sitePlugins}/>
                        </div>
                    }
                </div>
            </Page>

            {remainingPlugins && remainingPlugins.length > 0 && (
                remainingPlugins.map((pluginList, index) => (
                    <Page key={index} className='p-2'>
                        <div className='border rounded-sm h-full p-2'>
                            <div className='border rounded-sm'>
                               <PluginTable plugins={pluginList}/>
                            </div>
                        </div>
                    </Page>
                ))
            )}
        </div>
    );
};

export default PluginTablePage;